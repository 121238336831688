<template>
  <div id="app">
    <h1>Google Drive File Downloader</h1>
    <form @submit.prevent="submitTask">
      <label for="file_id">Enter Google Drive File ID:</label>
      <input type="text" v-model="fileID" id="file_id" required />
      <button type="submit">Submit Task</button>
    </form>

    <div v-if="showSuccessMessage" class="success-message">
      Task submitted successfully!
    </div>

    <h2>Task List</h2>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>File Name</th>
          <th>Status</th>
          <th>Created Time</th>
          <th>File Size</th>
          <th>Download URL</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="task in tasks" :key="task.id">
          <td>{{ task.id }}</td>
          <td>{{ task.file_name }}</td>
          <td>{{ task.status }}</td>
          <td>{{ new Date(task.created_time).toLocaleString() }}</td>
          <td>{{ formatFileSize(task.file_size) }}</td>
          <td v-if="task.status === 'completed'">
            <a :href="task.download_url" target="_blank">Download</a>
          </td>
          <td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileID: "",
      tasks: [],
      showSuccessMessage: false,
    };
  },
  methods: {
    async submitTask() {
      try {
        const response = await fetch("/api/tasks", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({ file_id: this.fileID }),
        });
        const data = await response.json();

        if (response.ok) {
          this.showSuccessMessage = true;
          setTimeout(() => {
            this.showSuccessMessage = false;
          }, 3000);
          this.fetchTasks(); // Refresh task list
        } else {
          console.error("Failed to submit task:", data);
        }
      } catch (error) {
        console.error("Error submitting task:", error);
      }
    },
    async fetchTasks() {
      try {
        const response = await fetch("/api/tasks");
        const data = await response.json();
        this.tasks = Object.values(data); // Convert tasks object to array
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    },
    formatFileSize(size) {
      if (size === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(size) / Math.log(k));
      return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
  },
  mounted() {
    this.fetchTasks();
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  margin-top: 60px;
}

form {
  margin-bottom: 20px;
}

.success-message {
  color: green;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid black;
}

th, td {
  padding: 8px;
  text-align: center;
}
</style>

